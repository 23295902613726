import { React, variantProvider, Theme } from '@/app'
import {
  CenterWrapper,
  Drawer,
  Logo,
  Image,
  Link,
  Text,
  View,
  Icon,
  Touchable,
} from '@/components'
import {
  useCodeleapContext,
  useComponentStyle,
  useBooleanToggle,
  useState,
} from '@codeleap/common'
import { useScrollEffect } from '@codeleap/web'
import { useLocation } from '@reach/router'

export type HeaderProps = {
  white?: boolean
}

type NavItemProps = {
  text: string
  link: string
  isHeaderWhite: boolean
  active?: boolean
}

const navItems = [
  { text: 'Home', link: '/' },
  { text: 'About', link: '/about' },
  { text: 'Media', link: '/media' },
  { text: 'Contact Us', link: '/contact' },
]

const NavItem = (props: NavItemProps) => {

  const { text, link, isHeaderWhite, active } = props

  const styles = useComponentStyle(componentStyles)

  return (
    <View variants={['column', 'alignCenter']}>
      <Link css={[isHeaderWhite ? styles.linkRed : styles.linkWhite]} text={`${text}`} variants={[`${isHeaderWhite ? 'primary' : ''}`]} to={`${link}`} />
      {active ? <View css={[styles.navItem, isHeaderWhite && styles.navItemRed]} /> : null}
    </View>
  )
}

export const Header = (props: HeaderProps) => {
  const styles = useComponentStyle(componentStyles)
  const [below, setBelow] = useState(false)
  const [drawer, toggleDrawer] = useBooleanToggle(false)

  const location = useLocation()
  const activeScreen = location.pathname

  const { white } = props

  useScrollEffect(setBelow, Theme.values.maxHeaderMenuHeight)

  const isHeaderWhite = white && !below

  return (
    <>
      <CenterWrapper
        styles={{
          innerWrapper: styles.wrapper,
          wrapper: below ? styles.floatingHeaderBelow : styles.floatingHeader,
        }}
      >
        <Link to={'/'} css={styles.logoWrapper}>
          <Logo style={styles.logo} variants={[`${isHeaderWhite ? 'primary' : ''}`]} />
        </Link>
        <Touchable onPress={() => toggleDrawer()} down={'mid'}>
          <Icon name={'bars'} style={isHeaderWhite ? styles.iconRed : styles.icon}/>
        </Touchable>
        <View variants={['gap:4']} up={'mid'}>
          {navItems.map((el, index) => <NavItem key={index} text={el.text} link={el.link} isHeaderWhite={isHeaderWhite} active={el?.link === activeScreen} />)}

        </View>
      </CenterWrapper>
      <Drawer
        open={drawer}
        toggle={toggleDrawer}
        variants={['fullScreen']}
        styles={{
          box: styles.fullHeight,
          wrapper: styles.fullHeight,
          // headerCloseButton: styles.drawerCloseButton,
        }}
        // showCloseButton

      >
        <CenterWrapper styles={{ wrapper: styles.floatingHeader, innerWrapper: { alignItems: 'flex-start' }}} variants={['column', 'paddingVertical:2']}>
          <View variants={['alignCenter', 'justifySpaceBetween', 'marginBottom:3']}>
            <Link to={'/'} css={styles.logoWrapper}>
              <Logo style={{ width: '30%' }} variants={['primary']} />
            </Link>
            <Touchable onPress={toggleDrawer}>
              <Icon name={'close'} style={styles.drawerCloseButton} />
            </Touchable>
          </View>
          <View variants={['column', 'gap:4']}>
            <Link text={'Home'} variants={['primary']} to={'/'} />
            <Link text={'About'} variants={['primary']} to={'/about'} />
            <Link text={'Media'} variants={['primary']} to={'/media'} />
            <Link text={'Contact Us'} variants={['primary']} to={'/contact'} />
          </View>
        </CenterWrapper>
      </Drawer>
    </>
  )
}

const componentStyles = variantProvider.createComponentStyle((theme) => ({
  wrapper: {
    ...theme.presets.row,
    ...theme.spacing.padding(1),
    justifyContent: 'space-between',
  },
  logo: {
    width: 140,
    [theme.media.down('small')]: {
      width: 120,
    },
  },
  logoWrapper: {
    marginRight: 'auto',
    [theme.media.down('small')]: {
      marginRight: 0,
      display: 'flex',
    },
  },
  floatingHeader: {
    position: 'fixed',
    zIndex: theme.values.zIndex.header,
    top: 0,
    left: 0,
    right: 0,
    transition: '0.3s ease',
    backgroundColor: 'transparent',
  },
  fullHeight: {
    height: '100vh',
  },
  floatingHeaderBelow: {
    position: 'fixed',
    zIndex: 999999,
    transition: '0.3s ease',
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: theme.colors.primary,
  },
  navLink: {
    [theme.media.down('small')]: {
      textDecoration: 'none',
    },
  },
  icon: {
    width: 24,
    height: 24,
    color: theme.colors.white,
    stroke: theme.colors.white,

    svg: {
      color: theme.colors.white,
      strokeColor: theme.colors.white,
    },
  },
  iconRed: {
    width: 24,
    height: 24,
    color: 'red',
    stroke: 'red',

    svg: {
      color: 'red',
      strokeColor: 'red',
    },
  },
  linkWhite: {
    transition: 'color 0.3s ease',
    color: theme.colors.white,
    margin: '0 auto',
    '&:hover': {
      color: theme.colors.primary,
    },
  },
  linkRed: {
    transition: 'color 0.3s ease',

    margin: '0 auto',
  },
  drawerCloseButton: {
    size: 20,
    color: 'red',
  },
  navItem: {
    backgroundColor: theme.colors.white,
    width: '130%',
    height: 2,
    borderRadius: 100000,
    marginTop: theme.spacing.value(0.8),
  },
  navItemRed: {
    backgroundColor: theme.colors.primary,
  },
}))
