export const Play = (props) => {
	return (
		<svg
			width='30'
			height='30'
			viewBox='0 0 30 30'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M14.9999 2.3877C12.4989 2.3877 10.0541 3.12932 7.97463 4.51879C5.89514 5.90826 4.27438 7.88316 3.3173 10.1938C2.36022 12.5044 2.1098 15.0469 2.59772 17.4998C3.08563 19.9527 4.28997 22.2059 6.05842 23.9743C7.82688 25.7428 10.08 26.9471 12.533 27.435C14.9859 27.923 17.5284 27.6725 19.839 26.7155C22.1496 25.7584 24.1245 24.1376 25.514 22.0581C26.9034 19.9786 27.6451 17.5338 27.6451 15.0329C27.6451 11.6792 26.3128 8.4628 23.9414 6.09138C21.57 3.71995 18.3536 2.3877 14.9999 2.3877ZM21.7262 15.8412L10.8875 21.2606C10.7498 21.3294 10.5967 21.3619 10.4429 21.3549C10.2891 21.348 10.1396 21.3018 10.0086 21.2208C9.8777 21.1398 9.76962 21.0267 9.6947 20.8921C9.61977 20.7576 9.58048 20.6062 9.58055 20.4522V9.6135C9.58063 9.4596 9.62004 9.30827 9.69503 9.17388C9.77002 9.03948 9.87811 8.92648 10.009 8.84558C10.14 8.76469 10.2894 8.7186 10.4431 8.71167C10.5969 8.70475 10.7498 8.73722 10.8875 8.80602L21.7262 14.2254C21.8761 14.3005 22.002 14.4158 22.0901 14.5584C22.1781 14.701 22.2247 14.8653 22.2247 15.0329C22.2247 15.2004 22.1781 15.3647 22.0901 15.5073C22.002 15.6499 21.8761 15.7652 21.7262 15.8403'
				fill='white'
			/>
		</svg>
	)
}
